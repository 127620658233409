import { graphql, useStaticQuery } from 'gatsby';
import AccordionArrow from 'assets/icons/icon-arrow-down.svg';
import { useState } from 'react';
import { ServiceItem } from 'components/ServiceItem';
import { usePageLocale } from 'hooks/usePageLocale';
import {
  Wrapper,
  ServiceGroup,
  ServiceAccordionHead,
  ServiceAccordionHeadText,
  ServiceAccordionBody,
  ServiceAccordion,
  ServiceAccordionIcon,
} from './styles';

const ServicesList = ({ showPrice = false }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsSServicesList {
        nodes {
          locale
          allServices {
            title
            servicesId: originalId
            relatedServices {
              id: originalId
              name: title
              priceFrom
            }
          }
        }
      }
    }
  `);

  const { pageLocale } = usePageLocale();
  const { allDatoCmsSServicesList: { nodes } } = data;

  const firstServicesId = nodes
    .filter(({ locale }) => locale === pageLocale)
    .flatMap(({ allServices }) => allServices)
    .map(({ servicesId }) => servicesId)[0];

  const [openAccordions, setOpenAccordions] = useState({ [firstServicesId]: true });

  const handleAccordionClick = (servicesId) => {
    setOpenAccordions((prevOpenAccordions) => ({
      ...prevOpenAccordions,
      [servicesId]: !prevOpenAccordions[servicesId],
    }));
  };

  return (
    <Wrapper>
      <ServiceGroup>
        {nodes.filter(({ locale }) => locale === pageLocale).map(({ allServices }) => {
          return allServices.map(({ title, relatedServices, servicesId }) => (
            <ServiceAccordion key={servicesId} className={openAccordions[servicesId] ? 'is-open' : ''}>
              <ServiceAccordionHead onClick={() => handleAccordionClick(servicesId)}>
                <ServiceAccordionHeadText>
                  <span>{title}</span>
                  <span>{`${relatedServices.length} послуг`}</span>
                </ServiceAccordionHeadText>
                <ServiceAccordionIcon as={AccordionArrow} />
              </ServiceAccordionHead>
              {openAccordions[servicesId] && (
                <ServiceAccordionBody>
                  {relatedServices?.map(({ name, id, priceFrom }) => (
                    <ServiceItem key={id} recordId={id} name={name} priceFrom={priceFrom} showPrice={showPrice} />
                  ))}
                </ServiceAccordionBody>
              )}
            </ServiceAccordion>
          ));
        })}
      </ServiceGroup>
    </Wrapper>
  );
};

export { ServicesList };
