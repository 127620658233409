import styled from 'styled-components';
import { media } from 'styles/mixins';

export const Wrapper = styled.div`
    > div {
        ${media.mobile`
          margin: 0 -16px;
          border-radius: 0;
          padding: 0;
        `}
    }
`;

export const ServiceGroup = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 9px;

    ${media.mobile`
      gap: 1px;
    `}
`;

export const ServiceAccordion = styled.li`
  padding: 36px;
  background-color: #FAF9FA;
  border-radius: 10px;

    ${media.mobile`
      padding: 18px;
      border-radius: 0;
    `}
`;

export const ServiceAccordionHead = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  
  span:nth-child(1) {
    font-size: 27px;
    font-weight: 600;
    line-height: 36px;
    margin-right: 18px;

      ${media.mobile`
          font-size: 22px;
          line-height: 27px;
        `}
  }
  
  span:nth-child(2) {
    color: #A19EA5;
  }
`;

export const ServiceAccordionHeadText = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: auto;
    align-items: center;

    ${media.mobile`
          flex-direction: column !important;
          align-items: flex-start;
        `}
`;

export const ServiceAccordionBody = styled.div`
  display: grid;
  grid-gap: 18px;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 18px;

    ${media.tablet`
          grid-template-columns: repeat(2, 1fr);
        `}

    ${media.mobile`
          grid-template-columns: repeat(1, 1fr);
        `}
`;

export const ServiceAccordionIcon = styled.svg`
  transition: all 0.2s ease;

  .is-open & {
    transform: rotate(-180deg);
  }
`;
